<template>
  <layout title="Agenda" :options="options" :rotas="rotas">
    <v-sheet
      :dark="$theme.dark"
      :light="$theme.light"
      style="background-color: #f2f2f2;"
      class="expande-horizontal sheet-container pt-2"
    >
      <v-flex class="px-3 fonte" xs12 md3>
        <v-text-field
          hide-details
          filled
          dense
          v-model="filter.search"
          append-icon="mdi-magnify"
          placeholder="Pesquise um agendamento"
          class="fonte-bold"
        ></v-text-field>
        <div class="expande-horizontal centraliza pt-1">
          <cosmos-filter-date v-model="dateFilter"></cosmos-filter-date>
        </div>
        <v-list
          class="px-0 ma-0 fonte windows-style-content pr-2"
          color="transparent"
          style="max-height: 88vh; overflow: auto;"
          v-if="filtereds.length > 0"
        >
          <v-list-item @click="openEvent(event)" v-for="event in filtereds" :key="event._id" class="px-2 mx-0"
            :class="{
              'list-events-item': get_external_movimentation._id !== event._id,
              'list-events-item-selected elevation-6': get_external_movimentation._id === event._id
            }"
          >
            <v-icon class="mr-3" size="23" color="orange" v-if="get_external_movimentation._id === event._id">mdi-eye-circle-outline</v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span :class="{
                  'white--text': get_external_movimentation._id === event._id
                }" style="font-size: 14pt;" class="fonte-bold">{{ event.type | typeFilter }}</span>
                <span style="font-size: 10pt;" class="grey--text ml-2">{{ event.visit_date }} {{ event.visit_hour }}</span>
              </v-list-item-title>
              <v-list-item-subtitle v-if="get_external_movimentation._id === event._id" :class="{
                  'orange--text fonte-bold': get_external_movimentation._id === event._id
                }">
                Você está visualizando ao lado
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else :class="{
                  'grey--text': get_external_movimentation._id === event._id
                }">
                {{ event.name }}
              </v-list-item-subtitle>
              
            </v-list-item-content>
            <v-list-item-action>
              <v-btn text dark :color="getColor(event.status)" small depressed rounded>
                <span class="fonte-bold" style="padding-top: 2px;">{{ getStatus(event.status ) }}</span>
                <v-icon size="15">
                  {{ getIcon(event.status) }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-else class=" pa-12 expande-horizontal animate__animated animate__fadeInUp centraliza column" style="min-height: 30vh;">
          <v-icon size="42">mdi-calendar-alert-outline</v-icon>
          <span style="font-size: 16pt;" class="fonte-bold">Nenhum agendamento encontrado!</span>
          <span class="fonte grey--text text-center">Para criar um novo agendamento, clique no número do dia no calendário</span>
        </div>
      </v-flex>
      <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9>
        <ViewEvent ref="vEvent" v-if="get_external_movimentation._id" />
        <Calendar
          v-else
          class="animate__animated animate__fadeIn"
          @create-event="date => $refs.eventDialog.open(date, 'create-event')"
          @open-event="event => $refs.eventDialog.open(event, 'open-event')"
          :events="get_external_movimentations"
        />
        <EventDialog ref="eventDialog" />
      </v-flex>
      <v-dialog
        v-else
        transition="slide-x-transition"
        fullscreen
        :value="get_external_movimentation._id ? true : false"
      >
        <v-card>
          <ViewEvent ref="vEvent" />
        </v-card>
      </v-dialog>
    </v-sheet>
  </layout>
</template>

<script>
import Calendar from "../components/Calendar.vue";
import EventDialog from "../components/EventDialog.vue";
import ViewEvent from "../components/ViewEvent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dateFilter: "",
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Agenda",
          path: "/agenda"
        }
      ],
      options: [],
      filter: {
        search: ""
      }
    };
  },
  components: {
    Calendar,
    EventDialog,
    ViewEvent
  },
  filters: {
    typeFilter(value) {
      const types = [
        {
          name: "Visita",
          value: "visit"
        },
        {
          name: "Data limite",
          value: "limit_date"
        },
        {
          name: "Entrega",
          value: "delivery"
        },
        {
          name: "Ligação",
          value: "ligação"
        },
        {
          name: "E-mail",
          value: "email"
        },
        {
          name: "Whatsapp",
          value: "whatsapp"
        },
        {
          name: "Outros",
          value: "other"
        }
      ]
      let res = types.filter(i => i.value == value)[0].name || "";
      return res;
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedUser",
      "get_external_movimentation",
      "get_external_movimentations",
      "getPersonalizedLoading",
    ]),
    filtereds() {
      if (this.filter.search) {
        return this.get_external_movimentations.filter(item => {
          return (
            (item.name &&
              item.name
                .toLowerCase()
                .includes(this.filter.search.toLowerCase()))
          );
        });
      } else {
        return this.get_external_movimentations;
      }
    }
  },
  methods: {
    openEvent(event) {
      this.$store.commit('set_external_movimentation', event)
      setTimeout(() => {
        this.$refs.vEvent.open(event);
      }, 300);

    },
    getColor(value) {
      const status = {
        'waiting': "orange",
        'done': "blue",
        'canceled': "red",
        'on_the_way': "green",
        'lost': "red"
      } 
      return status[value];
    },
    getStatus(value) {
      const status = {
        'waiting': "Agendado",
        'done': "Concluído",
        'canceled': "Cancelado",
        'on_the_way': "Em andamento",
        'lost': "Perdido"
      } 
      return status[value];
    },
    getIcon(value) {
      const status = {
        'waiting': "mdi-clock-outline",
        'done': "mdi-check-circle",
        'canceled': "mdi-close-circle",
        'on_the_way': "mdi-truck-delivery",
        'lost': "mdi-alert-circle-outline"
      } 
      return status[value];
    },
    excluir_external_movimentation_dialog(item) {
      let text = "Deseja remover este external_movimentation?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_external_movimentation",
        action_value: item
      });
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_clientes",
      "listar_external_movimentations",
      "criar_estoque",
      "listar_external_movimentationcategorias",
      "criar_external_movimentation",
      "listar_estoques",
      "atualizar_external_movimentation",
      "setPersonalizedLoading",
      "createConfirmAction",
      "openModalViewExternalMovimentation"
    ]),
    criarOuAtualizar() {
      if (this.get_external_movimentation._id) {
        this.atualizar_external_movimentation();
      } else {
        this.criar_external_movimentation();
      }
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_external_movimentation", { new: true });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
    },
    createOrUpdate() {
      if (this.$refs.form.validate()) {
        if (this.get_external_movimentation._id) {
          this.atualizar_external_movimentation();
        } else {
          this.criar_external_movimentation();
        }
      }
    }
  },
  created() {
    this.listar_external_movimentations();
    this.listar_clientes();
  }
};
</script>

<style scoped>
.list-events-item {
  background: #fff;
  margin-bottom: 12px;
  border-radius: 6px;
}
.list-events-item-selected {
  background: #325286;
  margin-bottom: 12px;
  border-radius: 6px;
}
.sheet-container {
  min-height: 95vh;
  max-height: 95vh;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #1173ff;
}
</style>
