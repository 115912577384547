<template>
  <div class="animate__animated animate__fadeIn expande-horizontal column pt-0">
    <v-flex xs12>
      <div
        style="background: #fff;"
        class="windows-style expande-horizontal fonte column"
      >
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="35"
              color="#f2f2f2"
              class="mr-3"
              icon
            >
              <v-icon :color="$theme.primary">mdi-arrow-left</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ get_external_movimentation.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie o evento abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-list-item-action v-if="get_external_movimentation._id">
              <v-btn
                color="red"
                @click="excluir_cliente_dialog(get_external_movimentation)"
                outlined
                small
                >Excluir</v-btn
              >
            </v-list-item-action> -->
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <v-tabs v-model="tabs" dark :background-color="$theme.fourth">
      <v-tab class="fonte-bold">Andamento</v-tab>
      <v-tab class="fonte-bold">Detalhes do agendamento</v-tab>
    </v-tabs>
    <div
      v-if="tabs == 0"
      style="background: #fff; max-height: 80vh; overflow: auto;"
      class="windows-style-content centraliza expande-horizontal wrap"
    >
      <v-flex xs12 md9 class="px-3">
        <v-chip
          style="border-radius: 6px;"
          dark
          :color="$theme.primary"
          :key="participant._id"
          v-for="participant in participants"
          class="ml-2 mt-2 elevation-1"
        >
          <v-icon size="21" class="mr-2">mdi-account-circle-outline</v-icon
          >{{ participant.nome }}
        </v-chip>
        <v-timeline class="fonte" align-top dense>
          <v-timeline-item :color="$theme.primary" icon="mdi-flag" small dense>
            Agendado para
            <span class="font-weight-bold"
              >{{ get_external_movimentation.visit_date }}
            </span>
            às
            <span class="font-weight-bold">{{
              get_external_movimentation.visit_hour
            }}</span>
          </v-timeline-item>
          <!-- <v-timeline-item :color="$theme.third" small dense>
            asdasd
          </v-timeline-item> -->
        </v-timeline>
        <div v-show="!showPreview" class="expande-horizontal wrap px-6">
          <div style="border: 1px solid #ececec; border-radius: 6px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;" id="toolbar-container">
            <span class="ql-formats">
              <select class="ql-font"></select>
              <select class="ql-size"></select>
            </span>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-strike"></button>
            </span>
            <span class="ql-formats">
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span>
            <span class="ql-formats">
              <button class="ql-script" value="sub"></button>
              <button class="ql-script" value="super"></button>
            </span>
            <span class="ql-formats">
              <button class="ql-header" value="1"></button>
              <button class="ql-header" value="2"></button>
              <button class="ql-blockquote"></button>
              <button class="ql-code-block"></button>
            </span>
            <span class="ql-formats">
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-indent" value="-1"></button>
              <button class="ql-indent" value="+1"></button>
            </span>
            <span class="ql-formats">
              <button class="ql-direction" value="rtl"></button>
              <select class="ql-align"></select>
            </span>
            <!-- <span class="ql-formats">
              <button class="ql-link"></button>
              <button class="ql-image"></button>
              <button class="ql-video"></button>
              <button class="ql-formula"></button>
            </span> -->
            <span class="ql-formats">
              <button class="ql-clean"></button>
            </span>
          </div>
          <div
            style="border-radius: 6px; border-top-left-radius: 0px; border-top-right-radius: 0px; border: 1px solid #ececec;"
            class="expande-horizontal wrap"
            id="editorjs"
          ></div>
          <!-- <v-text-field
            rounded
            solo-inverted
            flat
            v-model="new_comment"
            :color="$theme.secondary"
            append-icon="mdi-send"
          ></v-text-field> -->
          <v-btn
            @click="save"
            class="ma-3 ml-0"
            rounded
            dark
            :color="$theme.primary"
            >Salvar</v-btn
          >
          <v-btn
            @click="closeEditor"
            class="ma-3 ml-0"
            dark
            text
            :color="$theme.primary"
            >cancelar</v-btn
          >
        </div>
        <div @click="openEditor" class="previewHtml" v-if="!htmlPreview && showPreview">
          <div class="fonte-bold ma-3">Clique para editar a descrição</div>
        </div>
        <div @click="openEditor" v-if="showPreview && htmlPreview" class="pa-2 previewHtml" v-html="htmlPreview"></div>
      </v-flex>
      <v-flex v-if="false" xs12 md3 class="px-2">
        <span class="fonte grey--text">Opções:</span>
        <v-list color="transparent">
          <v-list-item
            :style="
              `background: #f2f2f2; border-radius: 3pt; margin-bottom: 6px;`
            "
          >
            <v-list-item-content>
              <v-list-item-title>
                <span class="fonte font-weight-bold">
                  Cancelar agendamento
                </span>
              </v-list-item-title>
              <span class="fonte grey--text">
                Qualquer lembrete relacionado ao agendamento será pausado.
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="red">mdi-cancel</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-flex>
    </div>
    <div
      v-if="tabs == 1"
      style="background: #fff;"
      class="windows-style-content centraliza expande-horizontal wrap"
    >
      <v-form ref="form">
        <v-flex class="px-2 mt-2 mb-2" xs12>
          <div
            @click="$refs.deliveryInput.open(get_external_movimentation)"
            class="fonte"
            style="cursor: pointer;"
          >
            <div
              v-if="
                get_external_movimentation.address_destiny &&
                  get_external_movimentation.address_destiny.number
              "
            >
              <v-icon :color="$theme.primary">
                mdi-map-marker-path
              </v-icon>
              {{ get_external_movimentation.address_destiny.street }},
              {{ get_external_movimentation.address_destiny.bairro }} Nº
              {{ get_external_movimentation.address_destiny.number }}
            </div>
            <div v-else>
              <v-icon size="15" color="red">
                mdi-map-marker
              </v-icon>
              <span :style="`color: red`" class="fonte">
                clique aqui para informar um endereço
              </span>
            </div>
          </div>
        </v-flex>
        <v-flex class="px-2 pt-3" xs12>
          <div class="expande-horizontal centraliza">
            <v-autocomplete
              v-model="get_external_movimentation.type"
              label="Tipo de agendamento"
              placeholder="ex: Visita"
              item-text="name"
              filled
              hide-details
              item-value="value"
              :rules="[v => !!v || 'Preencha este campo']"
              :items="types"
              dense
            ></v-autocomplete>
          </div>
        </v-flex>
        <v-flex class="px-2 pt-3 mb-2" xs12>
          <div class="expande-horizontal centraliza">
            <v-autocomplete
              v-model="get_external_movimentation.executor"
              label="Usuário Responsável"
              placeholder="ex: Sara"
              item-text="nome"
              filled
              hide-details
              item-value="_id"
              :rules="[v => !!v || 'Preencha este campo']"
              :items="get_funcionarios.docs"
              dense
            ></v-autocomplete>
          </div>
        </v-flex>
        <!-- <v-flex class="px-2" xs12>
          <div class="expande-horizontal centraliza">
            <v-time-picker
              format="24hr"
              full-width
              dark
              no-title
              color="#333"
              v-model="get_external_movimentation.visit_hour"
            ></v-time-picker>
          </div>
        </v-flex> -->
        <v-flex class="px-2" xs12>
          <v-autocomplete
            class="fonte"
            dense
            label="Cliente"
            :items="get_clientes.docs"
            item-text="nome"
            return-object
            hide-details
            filled
            @click:prepend-inner="abre_modal_view_cliente"
            prepend-inner-icon="mdi-account-plus-outline"
            :rules="[v => !!v || 'Preencha este campo']"
            v-model="get_external_movimentation.client"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs12>
          <div class="pa-2 expande-horizontal centraliza">
            <v-textarea
              v-model="get_external_movimentation.name"
              dense
              filled
              hide-details
              label="Descrição"
              :color="$theme.primary"
              :rules="[v => !!v || 'Preencha este campo']"
            ></v-textarea>
          </div>
        </v-flex>

        <div class="expande-horizontal">
          <v-flex class="px-2 mb-2" xs6>
            <div class="expande-horizontal centraliza">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="get_external_movimentation.visit_date"
                    label="Data do agendamento"
                    hide-details
                    v-bind="attrs"
                    filled
                    :rules="[v => !!v || 'Preencha este campo']"
                    :color="$theme.primary"
                    @blur="parseDate(get_external_movimentation.visit_date)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateUnformatted"
                  no-title
                  :color="$theme.primary"
                  @input="formatDate(dateUnformatted)"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-flex>
          <v-flex class="px-2" xs6>
            <v-text-field
              v-model="get_external_movimentation.visit_hour"
              filled
              hide-details
              label="Hora"
              v-mask="['##:##']"
              :color="$theme.primary"
              :rules="[
                v => !!v || 'Preencha este campo',
                v => (v && v.length === 5) || 'Preencha este campo'
              ]"
            ></v-text-field>
          </v-flex>
        </div>
      </v-form>
      <v-flex style="background-color: #fff;" class="pa-2" xs12>
        <div
          v-if="
            get_external_movimentation.type &&
              (!!get_external_movimentation.address_destiny ||
                get_external_movimentation.type !== 'delivery' ||
                get_external_movimentation.type !== 'visit') &&
              !!get_external_movimentation.executor
          "
          class="expande-horizontal centraliza"
        >
          <v-btn
            v-if="!get_external_movimentation._id"
            dark
            @click="valida_form"
            :color="$theme.primary"
          >
            <span class="fonte white--text"> Agendar </span>
          </v-btn>
          <v-btn v-else dark @click="valida_form" :color="$theme.primary">
            <span class="fonte white--text"> Atualizar agendamento </span>
          </v-btn>
        </div>
      </v-flex>
    </div>

    <DeliveryInput
      ref="deliveryInput"
      @input="setAddressDestiny($event)"
      v-model="get_external_movimentation.address_destiny"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DeliveryInput from "./DeliveryInput.vue";
import ModalCreateClient from "@/apps/44#Clientes/components/modalView.vue";
// import EditorJS from "@/apps/shared/assets/js/editor.js";
// import List from "@/apps/shared/assets/js/list.js";
// import Header from "@/apps/shared/assets/js/header.js";
// import Quill from 'quill';

export default {
  components: {
    DeliveryInput,
    ModalCreateClient
  },
  props: ["origin"],
  computed: {
    ...mapGetters([
      "get_external_movimentation",
      "getLoggedUser",
      "get_clientes",
      "getLoggedUser",
      "get_funcionarios"
    ]),
    participants() {
      const client = this.get_external_movimentation.client;
      const executor = this.get_external_movimentation.executor;
      const participants = [];
      if (client) {
        participants.push(client);
      }
      if (executor) {
        participants.push(executor);
      }
      return participants;
    }
  },
  data() {
    return {
      showPreview: true,
      htmlPreview: "",
      opened: false,
      from: "",
      editor: null,
      dateUnformatted: null,
      menu: false,
      types: [
        {
          name: "Visita",
          value: "visit"
        },
        {
          name: "Data limite",
          value: "limit_date"
        },
        {
          name: "Entrega",
          value: "delivery"
        },
        {
          name: "Ligação",
          value: "ligação"
        },
        {
          name: "E-mail",
          value: "email"
        },
        {
          name: "Whatsapp",
          value: "whatsapp"
        },
        {
          name: "Outros",
          value: "other"
        }
      ],
      tabs: 0
    };
  },
  mounted() {
    // this.editor = new Quill('#editorjs', {
    //   modules: { toolbar: true },
    //   theme: 'snow'
    // });
    this.editor = new Quill("#editorjs", {
      modules: {
        syntax: true,
        toolbar: "#toolbar-container"
      },
      placeholder: "Descreva como foi o evento...",
      theme: "snow"
    });
  },
  methods: {
    ...mapActions([
      "criar_cliente",
      "atualizar_cliente",
      "fecha_modal_view_cliente",
      "abre_modal_view_cliente",
      "listar_external_movimentations",
      "fecha_modal_view_gestordepedido",
      "abre_modal_view_gestordepedido"
    ]),
    save() {
      let use_case = "external_movimentations/update-external-movimentation";
      let payload = this.get_external_movimentation;
      let html = this.editor.root.innerHTML;
      this.htmlPreview = html;
      this.showPreview = true;
      payload.comments = [JSON.stringify(this.editor.getContents()), html];
      // console.log('asd', payload.comments[0])
      // console.log('asd', payload.comments[0])
      this.$run(use_case, payload)
        .then(res => {
          this.listar_external_movimentations();
        })
        .catch(e => {
          this.$store.dispatch("createGlobalMessage", {
            type: "warning",
            message: e.response.data.message,
            timeout: 4000
          });
          this.opened = true;
        });
    },
    randomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getColor(value) {
      const status = {
        waiting: "orange",
        done: "blue",
        canceled: "red",
        on_the_way: "green",
        lost: "red"
      };
      return status[value];
    },
    getStatus(value) {
      const status = {
        waiting: "Agendado",
        done: "Concluído",
        canceled: "Cancelado",
        on_the_way: "Em andamento",
        lost: "Perdido"
      };
      return status[value];
    },
    getIcon(value) {
      const status = {
        waiting: "mdi-clock-outline",
        done: "mdi-check-circle",
        canceled: "mdi-close-circle",
        on_the_way: "mdi-truck-delivery",
        lost: "mdi-alert-circle-outline"
      };
      return status[value];
    },
    selectType(type) {
      const p = this.get_external_movimentation;
      this.get_external_movimentation = {
        ...p,
        type
      };
    },
    setAddressDestiny(address) {
      const p = this.get_external_movimentation;
      this.get_external_movimentation = {
        ...p,
        address_destiny: address
      };
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      this.get_external_movimentation.visit_date = `${day}/${month}/${year}`;
      this.menu = false;
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      this.dateUnformatted = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    valida_form() {
      if (
        this.get_external_movimentation.type === "delivery" &&
        !this.get_external_movimentation.address_destiny.zip_code
      ) {
        this.$store.dispatch("createGlobalMessage", {
          type: "warning",
          message: "Informe o endereço de entrega",
          timeout: 4000
        });
        return;
      }
      if (this.$refs.form.validate()) {
        this.opened = false;
        let use_case = "";
        if (this.get_external_movimentation._id) {
          use_case = "external_movimentations/update-external-movimentation";
        }
        if (this.from === "sale" && !this.get_external_movimentation._id) {
          use_case = "external_movimentations/create-from-transaction";
        }
        if (this.from !== "sale" && !this.get_external_movimentation._id) {
          use_case = "external_movimentations/create-from-calendar";
        }
        if (this.get_external_movimentation._id) {
          this.$run(use_case, this.get_external_movimentation)
            .then(res => {
              this.$store.dispatch("createGlobalMessage", {
                type: "success",
                message: "O agendamento foi atualizado 😎",
                timeout: 2000
              });
              if (this.origin && this.origin === "sale-body") {
                const sale_updated = {
                  ...this.$store.getters.get_gestordepedido,
                  external_movimentation: res.data
                };
                if (this.$route.name == "Gestor de Vendas") {
                  this.fecha_modal_view_gestordepedido();
                  setTimeout(() => {
                    this.abre_modal_view_gestordepedido(sale_updated);
                  }, 300);
                }
              }
              this.listar_external_movimentations();
              this.close();
            })
            .catch(e => {
              console.log("e dfdfdf", e);
              this.$store.dispatch("createGlobalMessage", {
                type: "warning",
                message: e.response.data.message,
                timeout: 4000
              });
              this.opened = true;
            });
        } else {
          this.$run(use_case, this.get_external_movimentation)
            .then(res => {
              this.$store.dispatch("createGlobalMessage", {
                type: "success",
                message: "Agendado 🚀",
                timeout: 2000
              });
              if (this.origin && this.origin === "sale-body") {
                const sale_updated = {
                  ...this.$store.getters.get_gestordepedido,
                  external_movimentation: res.data
                };
                if (this.$route.name == "Gestor de Vendas") {
                  this.fecha_modal_view_gestordepedido();
                  setTimeout(() => {
                    this.abre_modal_view_gestordepedido(sale_updated);
                  }, 300);
                }
              }
              this.listar_external_movimentations();
              this.close();
            })
            .catch(e => {
              this.$store.dispatch("createGlobalMessage", {
                type: "warning",
                message: e.response.data.message,
                timeout: 2000
              });
              this.opened = true;
            });
        }
      }
    },
    closeEditor() {
      this.showPreview = true;
    },
    openEditor() {
      this.showPreview = false;

      setTimeout(() => {
        this.editor.setContents(JSON.parse(this.get_external_movimentation.comments[0]));
      }, 200);
    },
    open(get_external_movimentation, from) {
      this.editor.setContents("");
      this.showPreview = true;
      this.htmlPreview = get_external_movimentation.comments[1] || "";
      if (from === "sale") {
        this.get_external_movimentation = {
          transaction: get_external_movimentation._id,
          client: get_external_movimentation.compra.cliente,
          address_destiny: get_external_movimentation.compra.address || {}
        };
      }
      if (from === "open-event") {
        this.get_external_movimentation = get_external_movimentation;
      }
      if (from === "create-event") {
        this.get_external_movimentation = {
          visit_date: this.formatDate(get_external_movimentation.date)
        };
        this.dateUnformatted = get_external_movimentation.date;
      }
      if (!from && get_external_movimentation) {
        this.get_external_movimentation = get_external_movimentation;
      }
      if (!from && !get_external_movimentation) {
        this.get_external_movimentation = {};
      }
      this.from = from;
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.get_external_movimentation = {
        visit_date: null,
        type: "",
        address_destiny: null,
        visit_hour: "",
        executor: null
      };
      this.backToList();
    },
    backToList() {
      this.$store.commit("set_external_movimentation", {
        visit_date: null,
        type: "",
        address_destiny: null,
        visit_hour: "",
        executor: null
      });
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped>
#editorjs {
  border: 1px solid #333;
  padding: 10px;
}
.previewHtml {
  width: 100%;
  cursor: pointer;
  min-height: 30vh;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  margin-bottom: 12px;
}
.previewHtml:hover {
  background: #dedede;
}
</style>
